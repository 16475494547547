<template>
  <page>
    <template slot="headerRight">
      <el-button
        v-auth="`${isMiddleMan ? 'middleman:role:add' : 'role:add'}`"
        @click="
          addVisible = true
          currentItem = null
        "
        type="primary"
      >
        添加角色
      </el-button>
      <!-- <el-button @click="delHandler(false)" :disabled="!multipleSelection.length" type="danger"> 批量删除 </el-button> -->
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="params.roleName" placeholder="角色名称"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <!-- <el-table-column  type="selection" width="55" /> -->
        <el-table-column
          show-overflow-tooltip
          prop="roleName"
          label="角色名称"
        />
        <el-table-column show-overflow-tooltip prop="roleId" label="角色ID" />
        <el-table-column show-overflow-tooltip prop="remark" label="描述" />
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="创建时间"
        />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="
                `${isMiddleMan ? 'middleman:role:modify' : 'role:modify'}`
              "
              @click="editHandler(row)"
              type="text"
              size="mini"
              >编辑</el-button
            >
            <el-button
              v-auth="
                `${isMiddleMan ? 'middleman:role:delete' : 'role:delete'}`
              "
              @click="delHandler(row.roleId)"
              type="text"
              size="mini"
              >删除</el-button
            >
            <el-button
              v-auth="
                `${isMiddleMan ? 'middleman:role:author' : 'role:author'}`
              "
              @click="powerHandler(row)"
              type="text"
              size="mini"
              >角色权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData(true)"
      :visible.sync="addVisible"
      :current-item="currentItem"
    />

    <PowerModal
      v-if="powerVisible"
      :visible.sync="powerVisible"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal.vue'
import PowerModal from './components/PowerModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      visible: false,
      addVisible: false,
      powerVisible: false,
      currentItem: null,
      tableData: [],
      roleList: [],
      multipleSelection: [],
      params: {
        limit: 20,
        page: 1,
        roleName: ''
      },
      totalCount: 0
    }
  },
  created() {
    this.getData()
  },
  components: {
    AddModal,
    PowerModal
  },
  computed: {
    isMiddleMan() {
      return localStorage.getItem('userType') == 'MIDDLEMAN'
    }
  },
  methods: {
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        const res = await this.$api.roleManage.getRoleList(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.roleId)
    },

    async delHandler(roleId) {
      try {
        await this.$confirm(
          roleId ? '是否要删除该角色？' : '是否要批量删除所选角色？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        try {
          this.loading = true
          await this.$api.roleManage.deleteRole({
            roleId
          })
          this.$message.success('删除成功')
          this.getData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },

    async editHandler(row) {
      try {
        this.currentItem = row
        this.addVisible = true
      } catch (err) {
        console.log(err)
      }
    },

    powerHandler(row) {
      this.currentItem = row
      this.powerVisible = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
