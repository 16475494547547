var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"add-service",attrs:{"width":"1000px","destroy-on-close":true,"title":"分配权限","close-on-click-modal":false,"visible":_vm.show},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v("角色名称：")]),_c('span',[_vm._v(_vm._s(_vm.roleName))])]),(_vm.authList.length)?_c('div',{staticClass:"right"},[_c('el-checkbox',{on:{"change":_vm.selectAll},model:{value:(_vm.isAll),callback:function ($$v) {_vm.isAll=$$v},expression:"isAll"}},[_vm._v("全部权限")])],1):_vm._e()]),_c('div',{staticClass:"table-wrap"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"tr"},[_c('th',[_vm._v("模块")]),_c('th',[_vm._v("功能")])]),_vm._l((_vm.authList),function(item){return _c('div',{key:item.menuId,staticClass:"tr"},[_c('div',{staticClass:"td"},[_c('el-checkbox',{on:{"change":function (val) {
                _vm.checkChange(val, item)
              }},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}},[_vm._v(_vm._s(item.label))])],1),_c('div',{staticClass:"col-wrap"},_vm._l((item.children),function(subItem){return _c('div',{key:subItem.menuId,staticClass:"col"},[_c('div',{staticClass:"td"},[_c('el-checkbox',{on:{"change":function (val) { return _vm.checkChange(val, subItem, item.children); }},model:{value:(subItem.checked),callback:function ($$v) {_vm.$set(subItem, "checked", $$v)},expression:"subItem.checked"}},[_vm._v(_vm._s(subItem.label))])],1),_c('div',{key:subItem.menuId,staticClass:"td"},_vm._l((subItem.children),function(subSubItem){return _c('el-checkbox',{key:subSubItem.menuId,attrs:{"label":subSubItem.label},on:{"change":function (val) { return _vm.checkChange(
                      val,
                      subSubItem,
                      subItem.children,
                      item.children
                    ); }},model:{value:(subSubItem.checked),callback:function ($$v) {_vm.$set(subSubItem, "checked", $$v)},expression:"subSubItem.checked"}},[_vm._v(_vm._s(subSubItem.label))])}),1)])}),0)])})],2)]),(_vm.authList.length)?_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("保 存")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }